const seatCridColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'AdvertiserId',
    label: 'Advertiser ID',
    show: false,
    headerTitle: 'Advertiser ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'AdvertiserName',
    label: 'Advertiser Name',
    show: false,
    headerTitle: 'Advertiser Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotertbzoneId',
    label: 'Rmt. RTB Zone ID',
    show: true,
    headerTitle: 'Rmt. RTB Zone ID',
    sortable: true,
    thStyle: 'width:140px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotertbzoneName',
    label: 'Rmt. RTB Zone Name',
    show: true,
    headerTitle: 'Rmt. RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: false,
    headerTitle: 'Publisher Name',
    sortable: false,
  },
  {
    key: 'BannerzoneId',
    label: 'Banner Zone ID',
    show: false,
    headerTitle: 'Banner Zone ID',
    sortable: true,
    thStyle: 'width:130px',
    tdClass: 'text-right',
  },
  {
    key: 'BannerzoneName',
    label: 'Banner Zone Name',
    show: false,
    headerTitle: 'Banner Zone Name',
    sortable: false,
  },
  {
    key: 'VideozoneId',
    label: 'Video Zone ID',
    show: false,
    headerTitle: 'Video Zone ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
  },
  {
    key: 'VideozoneName',
    label: 'Video Zone Name',
    show: false,
    headerTitle: 'Video Zone Name',
    sortable: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: false,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: false,
    headerTitle: 'RTB Zone Name',
    sortable: false,
  },
  {
    key: 'PopfeedId',
    label: 'Pop Feed ID',
    show: false,
    headerTitle: 'Pop Feed ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
  },
  {
    key: 'PopfeedName',
    label: 'Pop Feed Name',
    show: false,
    headerTitle: 'Pop Feed Name',
    sortable: false,
  },
  {
    key: 'Seat',
    label: 'Seat',
    show: true,
    headerTitle: 'Seat',
    sortable: true,
    summed: false,
  },
  {
    key: 'Crid',
    label: 'CRID',
    show: true,
    headerTitle: 'CRID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Adomain',
    label: 'Adomain',
    show: true,
    headerTitle: 'Adomain',
    sortable: true,
    summed: false,
  },
  {
    key: 'Bundle',
    label: 'Bundle',
    show: true,
    headerTitle: 'Bundle',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Cat',
    label: 'Cat',
    show: true,
    headerTitle: 'Cat',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
    thStyle: 'width:70px',
  },
  {
    key: 'Bids',
    label: 'Bids',
    show: true,
    headerTitle: 'Bids',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'Wins',
    label: 'Wins',
    show: true,
    headerTitle: 'Wins',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'Bills',
    label: 'Bills',
    show: true,
    headerTitle: 'Bills',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'Losses',
    label: 'Losses',
    show: true,
    headerTitle: 'Losses',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'Impressions',
    label: 'Impressions',
    show: true,
    headerTitle: 'Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
    summed: false,
  },
  {
    key: 'NetImpressions',
    label: 'Net Impressions',
    show: true,
    headerTitle: 'Net Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: false,
  },
  {
    key: 'Ecpm',
    label: 'eCPM',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
  },
  {
    key: 'Spent',
    label: 'Spent',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'Revenue',
    label: 'Revenue',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'Profit',
    label: 'Profit',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: false,
  },
]
export default seatCridColumns
